.login-page-image-sec {

  // background-image: url("../../../assets/images/img/lg-bg-desktop.png");
  // padding: 30px;
  height: 100%;
  background-size: 100% 100%;
  position: relative;
  background-repeat: no-repeat;
  background-position: center top;

  @media (max-width: 1199px) {
    // background-image: url("../../../assets/images/img/login-lg-mb.png");
    background-position: left center;

  }

  @media (min-width: 1600px) {
    // background-image: url("../../../assets/images/img/lg-bg.png");
    background-position: left center;

  }

  //overflow: hidden;

  .title-container {
    p {
      font-size: 25px;
      color: white;
      margin: 0;
    }
  }
}

// .lg-bg {
//   background-image: url("../../../assets//images/img/lg-bg.png");
//   background-size: cover;
//   background-position: center top;
// }

.containerflix-column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
}

.img-container {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 20px 0px;
  flex-grow: 1;
  text-align: center;
  //justify-content: space-around;

  h3:nth-child(1) {
    font-size: 50px;
    margin: 0;

  }

  h3:nth-child(2) {
    font-size: 45px;
    margin: 10px 0 0 0;
    color: white;
    line-height: 50px;
  }

  img {
    width: 95%;
    height: 50%;
    margin-top: 30px;
  }
}

.logn-logo {
  position: absolute;
  left: 1%;
  top: 5%;
  width: 160px;

  @media (max-width: 1199px) {
    top: 3%;
    left: 2%;
  }
}

.loginPage {
  width: 448px;
  margin: 15px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid #E9EEF6;
  background: #FFF;
  box-shadow: 0px 4px 20px 0px rgba(84, 120, 250, 0.16);
  padding: 67px 56px 30px;
  box-sizing: border-box;
}
.login-mg h4 {
  font-size: 1.3em;
  font-weight: 700;
  color: #5478FA;
  margin-top: 0px;
  margin-bottom: 10px;
}

.login-mg h1 {
  font-size: 3em;
  font-weight: 900;
  color: #36373B;
  margin-top: 0px;
  margin-bottom: 10px;
}

.login-mg .MuiTypography-h5 {
  position: relative;
  font-size: 2em;
  margin-bottom: 0.6em;
}

.login-mg .MuiTypography-h5 span {
  position: relative;
}

.MuiTypography-h5 span::after {
  background-color: #5478FA;
  top: 112%;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 35px;
  transform: translate(-50%, 10px);
  -webkit-transform: translate(-50%, 10px);
}

.paragraph-login p {
  color: #242B42;
  font-size: 32px;
  font-weight: 800;
  line-height: normal;
  margin: 0px 0px 20px;
  margin-right: 30px;
  text-align: left;
}

.image-adjust {
  text-align: left;
  padding: 0px 0px 20px;
}

.img-rel {
  position: relative;
}

.img-rel h2 {
  position: absolute;
  top: 10px;
  right: 0px;
  left: 70px;
  color: #FFF;
  font-size: 28px;
  font-weight: 800;
  line-height: normal;
}


.makeStyles-formLogin-5 label {
  color: #666;
  font-size: 14px;
  margin-bottom: 16px !important;
  font-family: 'Plus Jakarta Sans' !important;
  font-weight: 500;
}
.Mui-focused {
  border: 0.5px solid #5478FA !important;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(78, 116, 250, 0.20) !important;
  border-radius: 10px;
  }
.makeStyles-formLogin-5 input {
  color: #242B42;
  font-size: 14px;
  border-radius: 10px !important;
  border: 0.5px solid transparent;
  background: #F9F9F9;
  box-shadow: none;
  height: 28px;
  font-family: 'Plus Jakarta Sans' !important;
}
.color-password-login {
  color: #4E74FA;
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
}
// .makeStyles-formLogin-5 .MuiInputBase-input {
//   border-radius: 38px !important;
// }
// .makeStyles-formLogin-5 .MuiOutlinedInput-root {
//   border-radius: 35px !important;
// }
.makeStyles-formLogin-5 fieldset {
  border-color: transparent !important;
}

// .makeStyles-formLogin-5 fieldset:focus {
//   border-color: #5478FA;
// }
.MuiInputBase-fullWidth {
  background: #F9F9F9;
  box-shadow: none;
  border: 0.5px solid transparent;
}
.makeStyles-formLogin-5 .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #000 !important;
  border-width: 0px !important;
}

.makeStyles-formLogin-5 .link-login {
  padding-left: 0px;
  padding-right: 0px;
}

.round-btn-login {
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: #fff;
  font-size: 14px;
  background-color: #5478FA;
  padding: 17px 45px;
  box-shadow:none;
  border-radius: 35px;
  width: 100%;
}

.makeStyles-formLogin-5 .round-btn {
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: #fff;
  font-size: 14px;
  background-color: #4E74FA;
  padding: 17px 45px;
  box-shadow: none;
  border-radius: 8px;
  width: 100%;
}
.round-btn span {
  color: #FAFAFB;
  font-family: 'Plus Jakarta Sans' !important;
  font-size: 15px;
  font-weight: 700;
  line-height: 12.418px;
  text-transform: capitalize;
}
.makeStyles-formLogin-5 .MuiInputBase-input {
  padding: 7px 14px 10px !important;
}

@media (max-width:767px) {
  .loginPage {
    width: 100%;
    border: none;
    background: #fff;
    box-shadow: none;
    padding: 0px 20px 20px;
}
  .paragraph-login p {
    line-height: normal;
    margin: 7px 0px;
    font-size: 33git px;
  }

  .image-adjust {
    padding: 13px 1px;
  }

}