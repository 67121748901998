/////////////////////////scss////////////////////////
.german-card-styles {
    width: 344px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto 30px !important;
    border-radius: 20px;
    background: #FFF;
}

.german-align-adjust {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.german-align-adjust img {
    max-width: 100%;
}
.german-card-style {
    border-radius: 20px !important;
    background: #FFF !important;
    box-shadow: none !important;
    margin: 0px;
    overflow: visible !important;
}

.german-outer-circle {
    width: 145px;
    border-radius: 20px;
    padding: 0px;
    height: 176px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #DCECFF;
}

.german-inner-circle {
    width: 126px;
    background: #FFF;
    border-radius: 15px;
    height: 158px;
    object-fit: cover;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 4px 0px #E6F2FF;
}
.bod-center-pdf {
    margin: 0px auto;
    width: 400px;
}
.bod-bg-full {
    background: #E6F2FF !important;
}
.bod-bg-pdf {
    padding: 33px 0px 15px;
    display: flex;
    flex-flow: column;
}
.logo-main {
    display: flex;
    padding-left: 52px;
}
.logo-main img {
    max-width: 100%;
    width: 297px;
}
.german-main-image-div {
    width: 100%;
    margin: 22px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -90px;
    z-index: 22;
    margin-bottom: -65px;
}
.dots-both-pdf {
    position: relative;
    width: 100%;
    display: flex;
    top:8px;
}
.dots-both-pdf:before, .dots-both-pdf:after {
    content: '';
    position: absolute;
    width: 23.498px;
    height: 23.498px;
    background: #E6F2FF;
    border-radius: 100%;
}

.dots-both-pdf:after {
    right: -10px;
}
.dots-both-pdf:before {
    left: -11px;
}
.dots-both-pdf2 {
    position: relative;
    width: 100%;
    display: flex;
    margin: 1px 0px 25px;
    justify-content: center;
    align-items: center;
}
.dots-both-pdf2 span {
    border-bottom: 1px dashed rgba(193, 193, 202, .5);
    display: block;
    height: 1px;
    width: calc(100% - 52px);
}
.dots-both-pdf2:before, .dots-both-pdf2:after {
    content: '';
    position: absolute;
    width: 23.498px;
    height: 23.498px;
    background: #fff;
    border-radius: 100%;
}

.dots-both-pdf2:after {
    right: -10px;
}
.dots-both-pdf2:before {
    left: -11px;
}

.german-card-div-row {
    display: flex;
    padding: 0px 26px 20px 30px;
}

.german-card-div-row .german-left-span {
    text-align: left;
    color: #666;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    width: 60%;

}
.german-card-div-row .german-left-span.fullww {
    width: 100%;

}

.german-main-container-card-style {
    background: #E6F2FF;
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100vh;
    max-width: 100% !important;
}

.german-card-div-row .german-right-span {
    color: #666;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;

}

.german-inner-text {
    color: #242B42;
    font-size: 14px;
    font-weight: 600;
    margin-top: 7px;
    display: block;
    text-align: left;
}

.german-status-design {
    color: #5BC94C;
    font-size: 11px;
    font-weight: 700;
    border-radius: 22.364px;
    border: 0.373px solid #5BC94C;
    background: rgba(91, 201, 76, 0.20);
    padding: 3px 3px 3px 11px;
    width: 71px;
}
.pdf-blue-bgg {
    background: #5478FA;
    border-radius: 20px;
    padding: 25px 0px 5px;
    overflow: hidden;
    margin-top: 10px;
}
.pdf-blue-bgg .german-card-div-row .german-left-span, .pdf-blue-bgg .german-card-div-row .german-right-span, .pdf-blue-bgg .german-inner-text {
    color: #fff;
} 
@media (max-width:767px){
    .bod-center-pdf {
        width: 100%;
    }
}