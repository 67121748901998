body {
    margin: 0 !important;
    font-family: 'Plus Jakarta Sans' !important;
    /*font-family: 'Roboto', 'sans-serif' !important;*/
    font-style: normal;
    background: #fff !important;
}

.MuiTypography-body1 {
    font-family: 'Plus Jakarta Sans' !important;
}

.MuiTypography-h4 {
    font-family: 'Plus Jakarta Sans' !important;
}

.MuiTableCell-root {
    font-family: 'Plus Jakarta Sans' !important;

}

.MuiAppBar-colorPrimary {
    background-color: #36BA80 !important;
}

.MuiButton-textPrimary {
    color: #36BA80 !important;
}

/* @font-face {
    font-family: 'Rajdhani';
    src: local('Rajdhani'), url(./fonts/glyphicons-halflings-regular.ttf) format('truetype');
}

@font-face {
    font-family: 'roboto-condensed';
    src: local('Lato'), url('./fonts/RobotoCondensed-Regular.ttf') format('opentype');
} */
/*src: local('Lato'), url(./fonts/RobotoCondensed-Regular.ttf) format('opentype');*/

@font-face {
    font-family: 'Plus Jakarta Sans';
    /*src: local('Lato'), url(./fonts/RobotoCondensed-Regular.ttf) format('opentype');*/
    src: local('Plus Jakarta Sans'), url('./fonts/PlusJakartaSans-Regular.ttf') format('opentype');
}