@import "../../assets/variables";

.table-title-container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  //padding: 14px 10px 2px 10px;

}


.date-inputs {
  display: flex;
}

.date-inputs>div+div {
  margin-left: 20px;
}

.lat-lng-color{
  color:#5478FA !important
}

.control-input-dashbox .MuiSelect-selectMenu {
  border-radius: 10px !important;
  border: 1px solid #E2E2E2;
  background: #F4F4F4 !important;
  height: 44px;
  width: 120px;
  box-sizing: border-box;
  color: #77838F !important;
  font-size: 14px;
  line-height: normal;
  padding: 12px 28px 8px 15px !important;
}

.control-input-dashbox .MuiAutocomplete-input {
  border-radius: 10px !important;
  border: 1px solid #E2E2E2;
  background: #F4F4F4 !important;
  height: 44px;
  width: 120px;
  box-sizing: border-box;
  color: #77838F !important;
  font-size: 14px;
  line-height: normal;
  padding: 12px 75px 8px 15px !important;
}

.control-input-dashbox .MuiAutocomplete-input.Mui-focused {
  color: #77838F;
  border: .5px solid #E2E2E2 !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}
.control-input-dashbox.Mui-focused .Mui-focused {
  border:none;
  box-shadow: none !important;
}
.control-input-dashbox .MuiOutlinedInput-root {
  border-radius: 10px !important;
  border: .5px solid transparent !important;
}
.control-input-dashbox .MuiInputBase-fullWidth {
  border: 0.5px solid transparent !important;
}
.control-input-dashbox.Mui-focused .MuiAutocomplete-input {
  border: none !important;
}
.MuiAutocomplete-popper {
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
  border-radius: 5px !important;
}
.control-input-dashbox .MuiFormLabel-root {
  color: #77838F;
  font-size: 14px;
  font-family: 'Plus Jakarta Sans' !important;
  font-weight: 500;
  top: -5px;
}

.control-input-dashbox .MuiFormLabel-filled {
  z-index: -1;

}

.control-input-dashbox .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.control-input-dashbox .MuiOutlinedInput-notchedOutline {
  border-color: #E2E2E2;
}

.control-input-dashbox .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #E2E2E2;
}

.control-input-dashbox .PrivateNotchedOutline-legendLabelled-24>span {
  display: none;
}

.table-title {
  // color: $primaryGreen;
  font-size: 28px !important;
  text-transform: capitalize;
  margin: 10px 0 !important;
  color: #242B42;
  font-family: 'Plus Jakarta Sans' !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal !important;
}

.table-title-graph {
  color: #242B42;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  margin: 0px !important;
}

.map-text {
  color: #666;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 19.229px;
}

.add-icon {
  //color: $primaryBlue;
  //border: 1px solid $primaryBlue;
  //border-radius: 30px;
  //padding: 6px 12px;
}


.bg-red {
  background: red;
  //color:red;
}

.color-red {
  color: red;
}

.color-black {
  color: black;
}

.bg-green {
  background: #098b51;
}

.color-green {
  color: #098b51;
}

.status-active {
  color: rgba(91, 201, 76, 0.20);
}

.status-inactive {
  color: rgba(255, 0, 0, 0.20);
}

.bg-purple {
  background: mediumpurple;
}

.color-purple {
  color: mediumpurple;
}

.bg-lightgray {
  background: #e0031f;
}

.color-lightgrey {
  color: rgba(145, 18, 18, 0.94);
}

.bg-lightpink {
  background: lightpink;
}

.color-lightpink {
  color: #e0031f;
}

.bg-lightskyblue {
  background: lightskyblue;
}

.color-lightskyblue {
  color: lightskyblue;
}

.bg-orange {
  background: orange;
}

.color-orange {
  color: orange;
}

.bg-light {
  background: rgba(106, 236, 177, 0.43);
  //color: $primaryGreen;
}

.log-div {
  position: absolute !important;
  right: 0px;
  color: #5478FA !important;
  // background-color: rgb(218, 254, 238) !important;
  border: 1px solid rgb(54, 186, 127) !important;
  border-radius: 7px !important;
  padding: 0 10px 0 10px !important;
  top: 5px;
}

.log-download-btn {
  color: #5478FA !important;
  font-weight: 400 !important;
  position: relative !important;
  top: -2px !important;

}

.log-clear-btn {
  color: #5478FA !important;
  font-weight: 400 !important;
  top: -3px !important;
}

.tb-pdf-download span {
  display: block;

}

.tb-pdf-download .pdf-linktext {
  color: #263238;
  font-size: 10px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.1px;
  white-space: nowrap;
}

.tbname-nation-thumb {
  display: flex;
}

.no-image{
  border-radius: 10px;
  width: 50px;
  height: 51.02px;
  border: 1px solid #EAF3FF;
  display: block;
  background: #EAF3FF;
}

.tbname-nation-thumb .tbname-nation-thumb-left {
  min-width: 50px;
  margin-right: 12px;
}
.tbname-nation-thumb .tbname-nation-thumb-left img {
  border-radius: 10px;
  width: 50px;
  height: 51.02px;
  border: 1px solid #EAF3FF;
}
.tbname-nation-thumb .tbname-nation-thumb-right {  
  align-self: center;
}
.tbname-nation-thumb .tbname-nation-thumb-right span {
 display: block;
}
.tbname-nation-thumb .tbname-nation-thumb-name {
  color: #242B42;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  margin-bottom: 3px;
}

.tbname-nation-thumb .tbname-nation-thumb-nation {
  color: #666;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.pagination-stylecustom .MuiInputBase-input {
  padding: 7px 26px 4px 13px !important;
  border-radius: 8px !important;
}
// .bg-light2 {
//   background:#5478FA !important;
//   color: #fff !important;
// }
.table-viewinfo {
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // width: 30%;
  // display: inline-block;
  color: #5A7CFA;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  padding: 7.601px 13.031px;
  border-radius: 100px;
  background: rgba(90, 124, 250, 0.10);

}
.table-scanicon {
  align-items: center;
}
.table-scanicon span {
  position: relative;
  top: 2px;
}
.table-more-option-btn-container {
  //display: flex!important;
  //justify-content: center;
  position: relative;

  .button-container {
    position: absolute;
    padding: 10px 0;
    top: 50px;
    right: 67%;
    background: #fcfafa;
    border: 1px solid #fcfafa;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    z-index: 50;

    .edit,
    .contract,
    .services {
      padding: 5px 20px 5px 10px;
      color: #4B686E;

      &:hover {
        background: #e3ebf6;
      }
    }
  }
}