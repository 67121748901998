.manage-manifest-paper {
  border: none;
  display: flex;
  outline: none;
  padding: 50px 80px 40px 80px;
  border-radius: 20px;
  flex-direction: column;
  background-color: #fff;
}

.modal-heading {
  margin-top: 0px;
  color: #242B42;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.stepper-div {
  padding: 12px 0px;
  width: 250px;
  margin: 0px auto;
}

.MuiStepLabel-label {
  margin-top: 16px !important;
  text-align: center !important;
  color: #A0AEC0 !important;
  font-family: 'Plus Jakarta Sans' !important;
  font-size: 16px !important;
  font-style: normal !important;
  line-height: normal !important;
}

.MuiStepLabel-label.MuiStepLabel-completed {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 500 !important;
}

.MuiStepConnector-line {
  border-color: #EEE !important;
  border-top-width: 2px !important;
}

.MuiStepIcon-root {
  color: rgba(0, 0, 0, 0.38) !important;
  fill: #FAFDFF !important;
  stroke-width: 1px !important;
  stroke: #EEE !important;
  width: 26px !important;
  height: 26px !important;
  border-radius: 100% !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #5478FA !important;
  fill: #5478FA !important;
  stroke: #5478FA !important;
  stroke-width: 0.5px !important;
}

.MuiStepIcon-text {
  fill: #fff !important;
  font-family: 'Plus Jakarta Sans' !important;
  color: #A0AEC0 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.file-label {
  margin-top: 25px;
  margin-bottom: 11px;
  color: #242B42;
  font-family: 'Plus Jakarta Sans' !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.file-para {
  text-align: center;
  font-size: 12px;
}

.MuiInputLabel-root {
  color: #242B42;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.form-wrapper .MuiSelect-selectMenu,
.form-wrapper .MuiInputBase-root {
  border-radius: 8px;
  border: 0.5px solid #EEE;
  background: #FAFDFF !important;
  height: 46px;
  padding: 13px 10px 10px !important;
  box-sizing: border-box;
  color: #161616 !important;
}

.form-wrapper .MuiInput-root .MuiSelect-selectMenu {
  border-radius: 0px;
  border: 0.5px solid transparent;
  background: transparent !important;
  height: 46px;
  padding: 13px 10px 10px !important;
  box-sizing: border-box;
  color: #161616 !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid transparent !important;
}

.form-wrapper .Mui-focused {
  border: 0.5px solid #5478FA !important;
}

.form-wrapper .Mui-focused fieldset {
  border-color: transparent !important;
  border-width: 0px !important;
}

.form-wrapper .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #EEE;
  border-width: .5px;
}

.form-wrapper .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.form-wrapper .MuiFormLabel-root {
  color: #242B42;
  font-family: 'Plus Jakarta Sans' !important;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}

.form-wrapper .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.form-wrapper .MuiSelect-selectMenu,
.form-wrapper .MuiInputBase-root {
  border-radius: 8px;
  border: 0.5px solid #EEE;
  background: #FAFDFF !important;
  height: 46px;
  padding: 13px 10px 10px !important;
  box-sizing: border-box;
  color: #161616 !important;
}
.form-wrapper .MuiAutocomplete-inputRoot{
  padding-right: 25px !important;
  padding-top: 3px !important;
  padding-bottom: 7px !important;
}
.form-wrapper .MuiAutocomplete-inputRoot input{
  min-width: 100% !important;
  padding-top: 10px !important;
}

.form-wrapper .datepicker-error-handler p.Mui-focused{
  border: none !important;
  box-shadow: none !important;
}

// .form-wrapper .Mui-focused .MuiSelect-selectMenu {
//   border: 0.5px solid #5478FA !important;
// box-shadow: 0px 0px 10px 0px rgba(78, 116, 250, 0.20) !important;
// }
.proceed-btn {
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: #fff;
  font-size: 14px;
  background-color: #5478FA;
  padding: 17px 45px;
  box-shadow: none;
  border-radius: 8px;
  width: 100%;
}

.file-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 147px;
  /* Set the desired height */
  border-radius: 8px;
  border: 0.5px dashed #5478FA;
  background: #FAFDFF;
  cursor: pointer;
  position: relative;
}

.file-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-input p {
  position: absolute;
  z-index: 3;
  left: 20px;
  bottom: 10px;
  width: calc(100% - 20px);
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 10px;
  text-align: center;
}

.upload-icon {
  margin-bottom: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.form-modal-close{
  position: absolute;
  right: -15px;
  top: -50px;
  cursor: pointer;
  z-index: 44;
}