/*.MuiOutlinedInput-input{*/
/*  padding: 10px !important;*/
/*}*/
.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    font-weight: bold !important;
    background-color: #5478FA1A;
    color: #5478FA !important;
}

.text-icon {
    margin-left: auto;
    display: flex;
    color: #5478FA !important;
    margin-top: -55px;

}


.text-capitalize {
    text-transform: capitalize !important;

}

.text-doc-none {
    text-decoration: none !important;
}

.mouse-pointer {
    cursor: pointer !important;

}


.app-logo-text {
    font-size: 20px;
    font-weight: bold !important;
    color: #fff !important;
    margin: 0 !important;
    padding: 0 !important;
    letter-spacing: 1px;
}

.app-logo-text img {
    width: 120px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.App-header {
    /*background-color: #282c34;*/
    background-color: indianred;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Mui-error {
    border: none !important;
}

.MuiInput-underline::befoFre,
.MuiInput-underline::after {

    border: none !important;
}

.bread-crumbs-container {
    background: #fcfafa !important;
    border-bottom: 1px solid #e3ebf6 !important;
    margin-top: -1px !important;
}

.sticky-header {
    position: fixed;
    /*width: -moz-available!important;*/
    padding: 12px 20px;
    z-index: 100;
    background: white;
}

.MuiDataGrid-toolbarContainer {
    padding: 15px 4px !important;
}

.MuiDataGrid-columnHeader {
    /*background: red !important;*/
    font-weight: bold !important;
    max-width: 180px !important;
}

.MuiDataGrid-columnsContainer {
    border: none !important;
}

.MuiDataGrid-columnHeaderTitleContainer {
    padding: 0 !important;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
    padding: 0 !important;
}

.MuiDataGrid-columnSeparator {
    display: none !important;
}

.MuiDataGrid-cell {
    /*background: #0d0d39 !important;*/
}

/*.MuiDataGrid-root*/
.MuiDataGrid-cell {
    /*background: red;*/
    border: none !important;
}

.MuiDataGrid-cell--textLeft {
    text-align: left !important;
}

.nav-item {
    margin-right: 20px;
}

nav-item:last-child {
    margin-right: 0px !important;
}

.nav-link {
    background: #fff;
    border-radius: 8px;
    border: 2px solid #b2ebd3;
    font-size: 14px;
    color: #6c757d;
    font-weight: 400;
    padding: .5rem 1rem;
}

.active-nav-link {
    color: #fff;
    background-color: #5478FA;
    border-color: #5478FA;
}

.add-search {
    padding-top: 0px;
    max-width: 220x;
    margin-right: 16px;
}

.add-search-wrap {
    background: transparent;
    position: relative;
    width: 100%;
    float: left;
}

.add-search-wrap input {
    height: 30px;
    margin: 0;
    padding: 4px 30px 4px 16px;
    background-color: #5478FA;
    border-radius: 7px;
    color: #fff;
    font-size: 14px;
    /*width: 100%;*/
    display: inline-block;
    border: none;
}

.add-search-wrap input:focus {
    outline: none;
}

.add-search-wrap input::-moz-placeholder {
    color: #fff;
    opacity: 1;
}

.add-search-wrap input:-ms-input-placeholder {
    color: #fff;
}

.add-search-wrap input::-webkit-input-placeholder {
    color: #fff;
}

.add-search-wrap button {
    border: none;
    background: transparent;
    position: absolute;
    right: -2px;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    cursor: pointer;
}

.add-search-wrap button svg {
    fill: #fff;
}

.add-icon {
    display: flex;
    border-radius: 19px;
    margin-right: 10px;
}

.add-icon .two-iconst {
    display: inline-block;
    border-radius: 19px;
}

.add-icon .cn_item {
    text-align: center;
    border-radius: 12px;
    width: 40px;
    height: 35px;
    display: inline-block;
    line-height: 45px;
}


.add-icon.two-iconst .cn_item.second-ic {
    background: #5478FA;
    border-radius: 0px 19px 19px 0px;
    margin-left: -4px;
}

.add-icon.two-iconst .cn_item.first-ic {
    background: #dafeee;
    border-radius: 19px 0px 0px 19px;
}

.add-icon .cn_item.second-ic {
    background: #5478FA;
    border-radius: 100%;
    margin-left: 0px;
}

.new-add-icon .cn_item.second-ic{
    padding: 11px 20px;
    border-radius: 8px;
    background: #5478FA;
    backdrop-filter: blur(6px);
    color: #FFF;
    text-align: center;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
}

.new-add-icon a{
    text-decoration: none;
}

.file-download-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 18px;
    cursor: pointer;
}

.file-download-circle{
    width: 33px;
    height: 33px;
    flex-shrink: 0;
    background-color: rgba(84, 120, 250, 0.10);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-download-span{
    color: #242B42;
    font-family: 'Plus Jakarta Sans';
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1px;
}

.small-loading {
    width: 18px !important;
    height: 18px !important;
}

.MuiAppBar-colorPrimary {
    background-color: #fff !important;
    border-bottom: 1px solid #E9EEF6;
    padding-top: 13px;
    padding-bottom: 10px;
}

.header-title-mn {
    color: #242B42;
    font-size: 28px;
    font-weight: 800;
    width: auto;
    flex-shrink: 0;
}

.header-title-mn span:last-child {
    font-size: 13px;
    display: block;
}

.inputColor .MuiInputBase-root {
    /* background-color: #5478FA;
    color: #fff; */
    border-radius: 8px !important;

}

.display-flex.MuiPaper-elevation2 {
    margin: 10px;
    border-radius: 0px;
}

/* .display-flex.MuiPaper-rounded{
    padding-bottom: 60px !important ;
} */
.tr:hover {
    box-shadow: 0px 0px 0px 1px rgba(99, 92, 92, 0.041);
    background-color: rgba(34, 31, 31, 0.041);

}

.contact_us {
    color: white;
    font-family: inherit;
    font-size: 10 !important;
    position: absolute;
    top: 88%;
    right: 5%
}

.contact_icon {
    margin-bottom: -6px;
}

.badge-tb {
    background: #5478FA1A;
    padding: 3px 11px;
    display: inline-block;
    color: #5478FA;
    border-radius: 6px;
}

.reprt-indcat::before {
    content: '';
    height: 8px;
    width: 8px;
    text-align: center;
    border-radius: 100%;
    color: #fff;
    background: #000;
    display: inline-block;
    margin-right: 5px;
}

.summary-color {
    background: #5478FA;
    color: #fff;
    border-radius: 6px;
    padding: 6px 15px 8px;
    margin-left: 10px;
    margin-top: 0px;
    font-size: 20px;
    font-weight: 600;
}



.color-password {
    /* border: none; */
    /* outline: none; */
    /* background: none; */
    /* white-space: nowrap; */
    color: #5478FA;
    font-size: 14px;
    /* cursor: pointer; */
}

.MuiSelect-selectMenu {
    background: #5478FA !important;
    color: #fff !important;
}

.edit-form-field .MuiSelect-selectMenu {
    background: transparent !important;
    color: #000 !important;
}

.manifest-data-item{
    background-color: #FFF;
    border-radius: 12px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    padding: 25px 20px;
}

/* .MuiSelect-icon {
    color: #fff !important;
} */

.center-pp-drop {
    text-align: center;
}

.pp-profile-img {
    width: 50px;
    padding: 12px 10px;
    margin: 0px auto;
}

.profile-container {
    color: #fff;
    margin-bottom: 5px;
    display: block;
}

.hr-drp-log {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0px;
    background: hsl(0deg 0% 100% / 22%);
    height: 1px;
}

.help-title-p {
    font-size: 14px;
    line-height: 1.3;
    padding-top: 5px;
}

.paper-border-btm {
    border-bottom: 1px solid #5478FA;
    box-shadow: none !important;
    border-radius: 0px !important;
}

.help-title {
    display: block;
    font-size: 14px;
}
