.expand-more-btn {
  position: fixed;
  width: 40px;
  background-color: #dafeee !important;
  bottom: 50px;
  right: 50px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg{
    font-size: 35px !important;
    color: #5478FA ;
    opacity: .7;

  }
}

//position: fixed;
//width: 40px;
///* background-color: red; */
//bottom: 50px;
//right: 50px;
//height: 40px;
//border-radius: 50px;
//display: flex;
//align-items: center;
//justify-content: center;
//background: whitesmoke;
