.form-title{
  font-size: 20px;
  margin: 5px 0 7px 0;
  text-transform: capitalize;
}

.show-hide-pass{
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-hide-pass span{
  margin-right: 10px;
  color: #92929D;
  // font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blue-span{
  color: #5478FA !important;
}


.form-wrapper .MuiInputBase-root.multi-select-menu{
  padding: 0px !important;
}

.form-wrapper .selected-country-chip{
  color: rgba(0, 0, 0, 0.87) !important;
  border: none !important;
  cursor: default !important;
  display: inline-flex !important;
  outline: 0 !important;
  padding: 0 !important;
  font-size: 0.8125rem !important;
  box-sizing: border-box !important;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  align-items: center !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  white-space: nowrap !important;
  border-radius: 16px !important;
  vertical-align: middle !important;
  justify-content: center !important;
  text-decoration: none !important;
  background-color: transparent !important;
  height: auto !important;
}

.form-wrapper .MuiAutocomplete-inputRoot{
  height: auto !important;
}