.qscan-control-input .MuiSelect-selectMenu {
  width:200px !important;
}
.qscan-control-input .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, 20px) scale(1);
 box-shadow: none !important;
 background: transparent !important;
 border: none !important;
 color: #77838F !important;
}

.filter{
  display: flex;
}

.manifest-filter{
  margin-right: 10px;
}