
.search-bar-filters-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.search-bar-filters-main-container1 {
  //display: flex;
  //align-items: center;
  //justify-content: space-between;
  padding: 10px 10px 20px 10px;
}

.drag-drop-search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;

  p {
    color: #0D0D39;
    opacity: .5;
  }
}

.search-bar-container {
  display: flex;
  align-items: center;
  //justify-content: space-between;
  //background: lightblue;
  //padding-top: 10px;
  //max-width: 20%;

  //img {
  //  //width: 100%;
  //  height: 15px;
  //}
  //
  //input {
  //  //flex-grow: 1;
  //  padding: 3px 5px;
  //  border: none;
  //  outline: none;
  //
  //  &::placeholder {
  //    text-transform: capitalize;
  //    color: #0D0D39;
  //    opacity: 1;
  //    text-transform: capitalize;
  //    font-size: 12px;
  //  }
  //}

}


@media (max-width: 1100px) {

  .search-bar-filters-main-container {
    flex-direction: column;
    align-items: flex-start;

    .search-bar-container {
      max-width: 100%;
    }
  }
}
