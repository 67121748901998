
.circle {
  flex-direction: revert;
  padding: 0px 5.4px;
  border-radius: 52%;
  margin-right: 5px;
  color: transparent;
  font-size: 10px;
  //visibility: hidden;
}
