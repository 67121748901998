.card-style {
    width: 344px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto 0px !important;
    border-radius: 0px !important;
    background: #FFF;
    text-align: center;
    box-shadow: none !important;
}

.approved-style {
    background: #6bff00;
    margin: 8px 5px;
    border: 1px solid #000;
}

.approved-style h2 {
    margin: 9px 0px;
    font-size: 13px;
}

.main-ttt-tp {
    font-size: 13px;
    font-weight: 700;
}

.align-adjust {
    margin-top: 20px;
}

.phill-align-adjust {
    margin-top: 20px;
}

.outer-circle {
    /* width: 156px; */
    border: 1px solid #DCECFF;
    /* border-radius: 100%; */
    padding: 0px;
    /* height: 156px; */
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.inner-circle {
    max-width: 156px;
    /* background: #FFF; */
    /* box-shadow: 0px 0px 4px rgba(220, 236, 255, 0.74); */
    /* border-radius: 100%; */
    max-height: 156px;
    object-fit: contain;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-image-div {
    width: 100%;
    margin: 22px auto;
    display: flex;
    justify-content: center;
    align-items: center;

}

.card-div-row {
    display: flex;
    justify-content: center;
    padding: 0px 0px 20px 0px;
    text-align: center;
    align-items: center;
}

.card-div-row .left-span {
    color: #000;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
}

.main-container-card-style {
    background: #FFF;
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100vh;
    max-width: 100% !important;
    font-family: monospace;
}

.card-div-row .right-span {
    text-align: right;
    color: #666;
    font-size: 11px;
    font-weight: 500;

}

.inner-text {
    color: #676464;
    font-size: 12px;
    font-weight: 600;
    margin-top: 12px;
    display: block;
}

.status-design {
    color: #5BC94C;
    font-size: 11px;
    font-weight: 700;
    border-radius: 22.364px;
    border: 0.373px solid #5BC94C;
    background: rgba(91, 201, 76, 0.20);
    padding: 3px 3px 3px 11px;
}

.modal-image {
    max-width: 100%;
    height: auto;
}

@media (max-width:767px) {
    .card-style {
        width: 100%;
    }
    .align-adjust {
        margin-top: 4px;
    }
    .phill-align-adjust {
        margin-top: 20px;
    }
    .inner-text {
        font-size: 12px;
        margin-top: 1px;
    }
}

@media (max-width:359px) {
    .card-div-row {
        padding: 0px 0px 3px 0px;
    }
    .main-image-div {
        margin: 3px auto;
    }
}