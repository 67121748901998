#right_panel {
    /*position: absolute;*/
    /*width: 96px;*/
    padding: 40px;
    height: 100%;
    left: 20px;
    /*resize: horizontal;*/
    background-color: #f0f0ff;
    /*padding: 20px;*/
    width: 300px;
    resize: both;
    overflow: auto;

}

.unique-visitors{
    display: inline-flex;
    color: #EEB80C;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    border-radius: 54px;
    background: rgba(238, 184, 12, 0.20);
    padding: 7px 18px;
    cursor: pointer;
}

.unique-visitors span{
    margin-left: 5px;
}

.request{
    display: inline-flex;
    color: #17B18F;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    border-radius: 54px;
    background: rgba(23, 177, 143, 0.20);
    padding: 7px 18px;
    cursor: pointer;
    margin-right: 10px;
}

.request span{
    margin-left: 5px;
}

.all-requests{
    display: inline-flex;
    color: #EEB80C;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    border-radius: 54px;
    background: rgba(238, 184, 12, 0.20);
    padding: 7px 18px;
    cursor: pointer;
    margin-left: 10px;
}

.all-requests span{
    margin-left: 5px;
}



/*#right_panel::after {*/
/*    content: '';*/
/*    background-color: #ccc;*/
/*    position: absolute;*/
/*    right: 0;*/
/*    width: 4px;*/
/*    height: 100%;*/
/*    cursor: ew-resize;*/
/*}*/
