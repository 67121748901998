
.help-modal-container {
  z-index: 500;
  position: fixed;
  right: 5px;
  top: 115px;
  //bottom: 0;
  width: 18%;
  min-height: 20vh;
  max-height: 80vh;
  overflow: auto;
  background-color: white;
  padding: 10px 10px 10px 20px;
  border-radius: 15px !important;
}

.upload-image-response{
  font-size: 14px;
}

.upload-image-response p:first-child{
  color: green;
}

.upload-image-response p{
  color: red;
}

.d-flex{
  display: flex;
}

.txt-style{
  margin-top: 4px;
}

.icon{
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px;
  width: 100px;
}

.MuiInputLabel-root {
  color: #242B42;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.form-wrapper .MuiSelect-selectMenu,
.form-wrapper .MuiInputBase-root {
  border-radius: 8px;
  border: 0.5px solid #EEE;
  background: #FAFDFF !important;
  height: 46px;
  padding: 13px 10px 10px !important;
  box-sizing: border-box;
  color: #161616 !important;
}

.form-wrapper .MuiInput-root .MuiSelect-selectMenu {
  border-radius: 0px;
  border: 0.5px solid transparent;
  background: transparent !important;
  height: 46px;
  padding: 13px 10px 10px !important;
  box-sizing: border-box;
  color: #161616 !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid transparent;
}

.form-wrapper .Mui-focused {
  border: 0.5px solid #5478FA !important;
}

.form-wrapper .Mui-focused fieldset {
  border-color: transparent !important;
  border-width: 0px !important;
}

.form-wrapper .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #EEE;
  border-width: .5px;
}

.form-wrapper .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.form-wrapper .MuiFormLabel-root {
  color: #242B42;
  font-family: 'Plus Jakarta Sans' !important;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}

.form-wrapper .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.form-wrapper .MuiSelect-selectMenu,
.form-wrapper .MuiInputBase-root {
  border-radius: 8px;
  border: 0.5px solid #EEE;
  background: #FAFDFF !important;
  height: 46px;
  padding: 13px 10px 10px !important;
  box-sizing: border-box;
  color: #161616 !important;
}