@import "scss/login";
@import "scss/sidebar";
@import "scss/main";

.mask1 {
  //-webkit-mask-image: url('./userIcon.png');
  mask-image: url('./images/userIcon.png');
  mask-image: radial-gradient(circle, black 50%, rgba(0, 0, 0, 0.5) 50%);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  //width: 60px;
}
